<template>
    <li>
        <p>{{ dayOfTheWeek }}</p>
        <img id="icon" class="weather-day-icon" :src="generateConditionIcon(day.conditionCode)"
            :alt="day.condition" />
        <p>{{ tempHigh }}<span class="degree-f">&deg;F</span></p>
        <p>{{ tempLow }}<span class="degree-f">&deg;F</span></p>
    </li>
</template>

<script>
import { Icons } from "../../../../../public/iconCodes.js";

const weekDay = {
    0: "SUN",
    1: "MON",
    2: "TUE",
    3: "WED",
    4: "THR",
    5: "FRI",
    6: "SAT",
};

export default {
    name: 'weekly-forecast',
    props: {
        day: Object
    },
    computed: {
        dayOfTheWeek() {
            let newDay = new Date(this.day.day);
            return weekDay[newDay.getDay()];
        },
        tempHigh() {
            return parseInt(this.day.temperatureHigh);
        },
        tempLow() {
            return parseInt(this.day.temperatureLow);
        }
    },
    methods: {
        generateConditionIcon(conditionCode) {
            return Icons[conditionCode];
        }
    },
}
</script>

<style scoped>
.degree-f {
    font-size: 13px;
    vertical-align: text-top;
}

li {
    list-style-type: none;
}

p {
    margin: 0px;
}
.weather-day-icon {
    margin-top: 10px;
    margin-bottom: 5px;
    width: 50px;
    height: 50px;
}
</style>