<template>
    <div class="current-weather-wrapper">
        <p class="location">{{ location }} </p>
        <ul class="current-weather-panel">
            <li class="current-weather-pane">
                <img class="current-weather-icon"
                    src="https://saweatherstation.blob.core.windows.net/icons/thermometer.svg"
                    alt="Temperature" />
                <div>
                    <p>
                        H: <b>{{ currentHigh }}<span class="degree-f">&deg;F</span></b>
                    </p>
                    <p>
                        L: <b>{{ currentLow }}<span class="degree-f">&deg;F</span></b>
                    </p>
                </div>
            </li>
            <li class="current-weather-pane">
                <img class="current-weather-icon" :src="precipitationIcon(currentWeather)" alt="Precipitation" />
                <p>
                    <b>{{ currentPrecipitationChance }}</b>%

                    <br />
                    <small>CHANCE</small>
                </p>
            </li>
            <li class="current-weather-pane">
                <img class="current-weather-icon"
                    src="https://saweatherstation.blob.core.windows.net/icons/wind.svg" alt="Wind" />
                <div>
                    <p>
                        <b>{{ currentWindSpeed }}</b><small> MPH</small>
                    </p>
                    <small>{{ currentWindDirection }}</small>
                </div>
            </li>
            <li class="current-weather-pane">
                <img class="current-weather-icon last-hour-precipitation-icon"
                    src="https://saweatherstation.blob.core.windows.net/icons/lastHourPrecip.svg"
                    alt="Precipitation In Last Hour">
                <p>
                    <b>{{rainfallInLastHour}}</b>
                    <br /><small> IN</small>
                </p>
            </li>
        </ul>
    </div>
</template>

<script>
    import { Icons } from "../../../../../public/iconCodes.js";
    const weatherDirections = {
        0: "N",
        1: "NE",
        2: "E",
        3: "SE",
        4: "S",
        5: "SW",
        6: "W",
        7: "NW",
    };
    export default {
        name: 'current-weather',
        props: {
            currentWeather: Object,
            location: String
        },

        computed: {
            currentTemp() {
                return parseInt(this.currentWeather.temperature);
            },

            feelsLike() {
                return parseInt(this.currentWeather.temperatureFeelsLike);
            },

            currentHigh() {
                return parseInt(this.currentWeather.temperatureHigh);
            },

            currentLow() {
                return parseInt(this.currentWeather.temperatureLow);
            },

            currentPrecipitationChance() {
                return parseInt(this.currentWeather.precipitationChance);
            },

            currentWindSpeed() {
                return parseInt(this.currentWeather.windSpeed);
            },

            currentWindDirection() {
                let direction = (this.currentWeather.windDirection + 22.5) % 360;
                direction = Math.floor(direction / 45);
                return weatherDirections[direction];
            },
            rainfallInLastHour() {
                return this.currentWeather.rainfallInLastHour.toFixed(2);
            }
        },
        methods: {
            precipitationIcon(data) {
                if (
                    data.precipitationChance > 0 &&
                    Icons[data.conditionCode] ===
                    "https://saweatherstation.blob.core.windows.net/icons/snow.svg"
                )
                    return "https://saweatherstation.blob.core.windows.net/icons/snowflake.svg";
                else
                    return "https://saweatherstation.blob.core.windows.net/icons/raindrop.svg";
            },
        }
    }
</script>


<style scoped>
    ul {
        padding: 0;
    }
    .bold {
        font-weight: bold;
    }

    .current-weather-pane {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        gap: 0.5em;
    }

    .current-weather-icon {
        align-self: center;
        width: 50px;
    }

    .current-weather-wrapper {
        display: flex;
        flex-direction: column;
    }

    .current-weather-panel {
        display: flex;
        justify-content: space-around;
        font-size: 20px;
    }

    .degree-f {
        position: relative;
        top: 2px;
        font-size: 13px;
        vertical-align: text-top;
        font-weight: normal;
    }

    .location {
        font-size: 20px;
    }

    @media screen and (max-width: 1499px) {
        .location {
            display: block;
        }
    }

    @media screen and (min-width: 1500px) {
        .location {
            display: none;
        }
    }

    @media screen and (max-width: 1262px) and (min-width: 800px) {
        .location {
            display: none;
        }
    }

    p {
        margin: 0px;
    }

    small {
        font-size: 17px;
        vertical-align: text-top;
    }
</style>