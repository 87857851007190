<template>
    <section id="widget-background" :style="{'background-image': backgroundImage}">
        <skeleton-loader v-if="showSkeleton && !fetchFailed" class="skeleton-loader"></skeleton-loader>
        <ErrorPanel v-if="fetchFailed" class="error-panel"></ErrorPanel>
        <desktop-weather-widget id="weather-widget" v-if="!mobileMode && isLoaded" class="desktop-weather-widget"
            :weatherData="weatherData" />
        <mobile-weather-widget v-if="mobileMode && isLoaded" class="mobile-weather-widget" :weatherData="weatherData" />
    </section>
</template>

<script>
    import MobileWeatherWidget from "./WeatherComponents/MobileWeatherWidget.vue";
    import SkeletonLoader from "./WeatherComponents/SkeletonLoader.vue";
    import ErrorPanel from "./WeatherComponents/ErrorPanel.vue";
    import DesktopWeatherWidget from "./WeatherComponents/DesktopWeatherWidget.vue";
    const MorningBackground = 'url("https://saweatherstation.blob.core.windows.net/images/WeatherBackground_Morning.png")';
    const DayBackground = 'url("https://saweatherstation.blob.core.windows.net/images/WeatherBackground_Day.png")';
    const EveningBackground = 'url("https://saweatherstation.blob.core.windows.net/images/WeatherBackground_Evening.png")';
    const NumberOfRetries = 3;

    export default {
        name: "weather-widget",
        components: {
            MobileWeatherWidget,
            SkeletonLoader,
            ErrorPanel,
            DesktopWeatherWidget
        },
        data() {
            return {
                backgroundImage: null,
                mobileMode: false,
                showSkeleton: true,
                isLoaded: false,
                weatherData: null,
                fetchFailed: false,
                currentFetchInterval: null,
                hourlyFetchInterval: null,
                dailyFetchInterval: null,
                currentWeatherIntervalDelayInMS: 300000, // 300000 // 30000
                hourlyWeatherIntervalDelayInMS: 3600000, // 3600000 // 40000
                dailyWeatherIntervalDelayInMS: 86400000, // 86400000 // 50000
            }
        },
        watch: {
            weatherData() {
                if (!this.fetchFailed) {
                    this.isLoaded = true;
                    this.showSkeleton = false;
                }
            }
        },
        async mounted() {
            this.weatherData = await this.getWeatherData(process.env.VUE_APP_WEATHER_API_ALL_URL, NumberOfRetries);
            this.backgroundImage = this.getBackgroundImage();
        },
        created() {
            this.checkScreenWidth();
            this.setFetchIntervals();
            window.addEventListener("resize", this.checkScreenWidth);
        },
        destroyed() {
            this.clearFetchIntervals();
            window.removeEventListener("resize", this.checkScreenWidth);
        },
        methods: {
            getBackgroundImage() {
                let hourOfTheDay = new Date().getHours();
                if (hourOfTheDay < 11 && hourOfTheDay >= 6)
                    return MorningBackground;

                else if (hourOfTheDay >= 11 && hourOfTheDay < 15)
                    return DayBackground;

                else
                    return EveningBackground;
            },
            checkScreenWidth() {
                if (window.innerWidth <= 700)
                    this.mobileMode = true;

                else
                    this.mobileMode = false;

            },
            clearFetchIntervals() {
                window.clearInterval(this.currentFetchInterval);
                window.clearInterval(this.hourlyFetchInterval);
                window.clearInterval(this.dailyFetchInterval);
            },
            async getWeatherData(url, retries) {
                return await fetch(url)
                    .then((response) => {
                        if (response.ok) {
                            return response.json();
                        }
                        if (retries > 0) {
                            console.log(`Failed call retrying ${retries} more time(s)`)
                            return this.getWeatherData(url, retries - 1);
                        }
                        throw new Error(response.status);
                    })
                    .then(data => data)
                    .catch((error) => {
                        console.error(error.message);
                        this.showFailedFetchMessage();
                    });
            },
            setFetchIntervals() {
                this.currentFetchInterval = window.setInterval(async () => {
                    this.weatherData.currentWeather = await this.getWeatherData(process.env.VUE_APP_WEATHER_API_CURRENT_URL, NumberOfRetries);
                }, this.currentWeatherIntervalDelayInMS);

                this.hourlyFetchInterval = window.setInterval(async () => {
                    this.weatherData.hourlyWeather = await this.getWeatherData(process.env.VUE_APP_WEATHER_API_HOURLY_URL, NumberOfRetries);
                }, this.hourlyWeatherIntervalDelayInMS);

                this.dailyFetchInterval = window.setInterval(async () => {
                    this.weatherData.dailyWeather = await this.getWeatherData(process.env.VUE_APP_WEATHER_API_DAILY_URL, NumberOfRetries);
                }, this.dailyWeatherIntervalDelayInMS);
            },
            showFailedFetchMessage() {
                this.fetchFailed = true;
                this.isLoaded = false;

                this.clearFetchIntervals();
            },
        }
    }
</script>

<style scoped>
    .error-panel {
        display: flex;
        height: 100%;
        font-size: 40px;
    }

    .desktop-weather-widget {
        height: 100%;
    }

    #widget-background {
        background-size: cover;
        background-image: v-bind(backgroundURL)
    }

    .skeleton-loader {
        height: 100%;
    }

    @media screen and (max-width: 700px) {
        .weather-widget>.mobile-weather-widget {
            display: grid;
            width: 100%;
        }
    }

    .mobile-weather-widget {
        display: none;
    }
</style>