<template>
    <ol class="hourly-weather-panel">
        <weather-hour class="weather-hour" v-for="hour in hourlyWeather.slice(0, 5)" :key="hour.time" :hour="hour">
        </weather-hour>
    </ol>
</template>

<script>
    import WeatherHour from "./WeatherHour.vue";

    export default {
        name: 'hourly-forecast',
        components: {
            WeatherHour
        },
        props: {
            hourlyWeather: Array
        },
    }
</script>

<style scoped>
    ol {
        padding: 0;
    }

    p {
        margin: 0px;
    }

    .hourly-weather-panel {
        justify-content: space-around;
    }

    .weather-hour {
        margin-block: auto;
        justify-content: center;
    }
</style>