<template>
	<div class="mobileWrapper">
		<div class="condition-and-temps">
			<img class="current-condition-icon" :src="generateConditionIcon(currentWeather.conditionCode)"
				alt="Current Weather Condition" />
			<p class="current-temp">
				<b>{{ currentTemp }}</b>
				<span class="current-temp-degree-f">&deg;F</span>
				<!-- <span class="bold">120</span><span class="current-temp-degree-f">&deg;F</span> -->
			</p>
			<p class="feels-like">
				FEELS LIKE {{ feelsLike }}<span class="feels-like-degree-f">&deg;F</span>
				<!-- FEELS LIKE 120<span class="feels-like-degree-f">&deg;F</span> -->
			</p>
		</div>
		<div class="current-weather-info-square">
			<div class="weather-box">
				<img class="mobile-icon"
					src="https://saweatherstation.blob.core.windows.net/icons/thermometer.svg"
					alt="Temperature" />
				<p>
					<b>{{ currentHigh }}</b>
					<span class="high-low-degree-f">&deg;F</span>
					<!-- <span class="bold">120</span><span class="high-low-degree-f">&deg;F</span> -->
					/
					<b>{{ currentLow }}</b>
					<span class="high-low-degree-f">&deg;F</span>
					<!-- <span class="bold">105</span><span class="high-low-degree-f">&deg;F</span> -->
				</p>
			</div>
			<div class="weather-box">
				<img class="mobile-icon" src="https://saweatherstation.blob.core.windows.net/icons/raindrop.svg"
					alt="Precipitation" />
				<p>
					<b>{{ currentPrecipitationChance }}</b>%
				</p>
			</div>
			<div class="weather-box">
				<img class="mobile-icon" src="https://saweatherstation.blob.core.windows.net/icons/wind.svg"
					alt="Wind" />
				<p>
					<b>{{ currentWindSpeed }}</b>
					<span> MPH {{ currentWindDirection }}</span>
				</p>
			</div>
			<div class="weather-box">
				<img class="mobile-icon"
					src="https://saweatherstation.blob.core.windows.net/icons/lastHourPrecip.svg"
					alt="Precipitation In Last Hour" />
				<p>
					<b>0.1</b><small> IN</small>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
	import { Icons } from "../../../../../public/iconCodes.js";
	const weatherDirections = {
		0: "N",
		1: "NE",
		2: "E",
		3: "SE",
		4: "S",
		5: "SW",
		6: "W",
		7: "NW",
	};

	export default {
		name: "mobile-current-weather",
		props: {
			currentWeather: Object,
			location: String,
		},
		computed: {
			currentTemp() {
				return parseInt(this.currentWeather.temperature);
			},
			feelsLike() {
				return parseInt(this.currentWeather.temperatureFeelsLike);
			},
			currentHigh() {
				return parseInt(this.currentWeather.temperatureHigh);
			},
			currentLow() {
				return parseInt(this.currentWeather.temperatureLow);
			},
			currentPrecipitationChance() {
				return parseInt(this.currentWeather.precipitationChance);
			},
			currentWindSpeed() {
				return parseInt(this.currentWeather.windSpeed);
			},
			currentWindDirection() {
				let direction = (this.currentWeather.windDirection + 22.5) % 360;
				direction = Math.floor(direction / 45);
				return weatherDirections[direction];
			},
		},
		methods: {
			generateConditionIcon(conditionCode) {
				return Icons[conditionCode];
			},
		},
	};
</script>

<style scoped>
	@font-face {
		font-family: Cerebri;
		src: url("/Fonts/Cerebri-Sans-Regular.ttf");
	}

	.condition-and-temps {
		line-height: 1;
		display: flex;
		flex-direction: column;
		gap: 0.5em;
		align-items: center;
	}

	.current-condition-icon {
		width: 60px;
	}

	.current-temp {
		font-size: 50px;
	}

	.current-temp-degree-f {
		font-size: 30px;
		vertical-align: top;
		position: relative;
		top: 2px;
	}

	.current-weather-info-square {
		display: grid;
		grid-template-columns: 50% 50%;
		grid-template-rows: 50% 50%;
		column-gap: 1em;
		row-gap: 1em;
		font-size: 15px;
	}

	.feels-like {
		font-size: 18px;
	}

	.feels-like-degree-f {
		font-size: 13px;
		vertical-align: top;
	}

	.high-low-degree-f {
		font-size: 11px;
		vertical-align: text-top;
	}

	.mobile-icon {
		width: 35px;
	}

	p {
		margin: 0px;
	}

	.weather-box {
		width: 95px;
	}

	.mobileWrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
	}
</style>