<template>
    <div id="time-display"> 
        <time id="date">{{ date | longDate}}</time>        
        <time id="clock">{{ time }}</time>
    </div>
</template>

<script>
import { setInterval } from 'timers';
import colors from "@/colors";

export default {
    name: "clockwidget",
    data() {
        return {
            colors,
            time: null,
            date: null
        }
    },
    created() {
        setInterval(this.updateClock, 500);
    },
    methods: {
        updateClock() {
            var time = new Date();
            this.time = time.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
            this.date = time.toLocaleDateString();
            this.time = this.time.replace(/^0+/, '');
        }
    }
}
</script>


<style>
time {
    font-size: 20pt;
}

#time-display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    background-color: #3b3b3b;
}

#time-display > *{
    text-align: center;
    color: rgb(255, 255, 255);
}
</style>