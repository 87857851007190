<template>
    <ol class="daily-weather-panel">
        <weather-day class="weather-day" v-for="day in dailyWeather.slice(0, 5)" :key="day.day" :day="day">
        </weather-day>
    </ol>
</template>

<script>
import WeatherDay from "./WeatherDay.vue";

export default {

    name: 'weekly-forecast',
    components: {
        WeatherDay
    },
    props: {
        dailyWeather: Array
    },
}
</script>


<style scoped>
ol {
    padding: 0;
}

p {
    margin: 0px;
}

.daily-weather-panel {
    justify-content: space-around;
}

.weather-day {
    margin-block: auto;
    justify-content: center;
}
</style>