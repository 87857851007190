<template>
    <li>
        <p>{{ forecastHour }}</p>
        <img class="weather-hour-icon" :src="generateConditionIcon(hour.conditionCode)" :alt="hour.condition" />
        <p>
            {{ hourTemp }}<span class="degree-f">&deg;F</span>
        </p>
        <img class="precipitation-icon" :src="precipitationIcon(hour)" alt="Precipitation Chance"/>
        <p class="precipitation-text">
            {{ precipitationChance }}%
        </p>
    </li>
</template>

<script>
import { Icons } from "../../../../../public/iconCodes.js";

export default {
    name: 'weather-hour',
    props: {
        hour: Object
    },
    computed: {
        forecastHour() {
            let localTime = new Date(this.hour.time);
            let hour = localTime.getHours();
            
            let period = hour >= 12 ? " PM" : " AM";
            let date = hour % 12 === 0 ? 12 : hour % 12;
            
            return date + period;
        },
        hourTemp() {
            return parseInt(this.hour.temperature);
        },
        precipitationChance() {
            return parseInt(this.hour.precipitationChance);
        }
    },
    methods: {
        generateConditionIcon(conditionCode) {
            return Icons[conditionCode];
        },
        precipitationIcon(data) {
            if (
                data.precipitationChance > 0 &&
                Icons[data.condition] ===
                "https://saweatherstation.blob.core.windows.net/icons/snow.svg"
            )
                return "https://saweatherstation.blob.core.windows.net/icons/snowflake.svg";
            else
                return "https://saweatherstation.blob.core.windows.net/icons/raindrop.svg";
        },
    }
}
</script>


<style scoped>
.degree-f {
    font-size: 13px;
    vertical-align: text-top;
}

li {
    list-style-type: none;
}

p {
    margin: 0px;
}

.precipitation-icon {
    width: 14px;
    height: 14px;
    vertical-align: middle;
}

.precipitation-text {
    display: inline-flex;
    vertical-align: middle;
    margin-left: 5px;
}

.weather-hour-icon {
    margin-top: 10px;
    width: 50px;
    height: 50px;
}
</style>