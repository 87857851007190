export const Icons = {
    /* Sunny Conditions */
    '1':'https://saweatherstation.blob.core.windows.net/icons/sunny.svg',
    '5':'https://saweatherstation.blob.core.windows.net/icons/sunny.svg',
    '30':'https://saweatherstation.blob.core.windows.net/icons/sunny.svg',
    '33':'https://saweatherstation.blob.core.windows.net/icons/sunny.svg',
    '37':'https://saweatherstation.blob.core.windows.net/icons/sunny.svg',
    /* Partly Cloudy Conditions */
    '2': 'https://saweatherstation.blob.core.windows.net/icons/partlysunny.svg',
    '3': 'https://saweatherstation.blob.core.windows.net/icons/partlysunny.svg',
    '4': 'https://saweatherstation.blob.core.windows.net/icons/partlysunny.svg',
    '6': 'https://saweatherstation.blob.core.windows.net/icons/partlysunny.svg',
    '34': 'https://saweatherstation.blob.core.windows.net/icons/partlysunny.svg',
    '35': 'https://saweatherstation.blob.core.windows.net/icons/partlysunny.svg',
    '36': 'https://saweatherstation.blob.core.windows.net/icons/partlysunny.svg',
    '38': 'https://saweatherstation.blob.core.windows.net/icons/partlysunny.svg',
    /* Cloudy Conditions */
    '7': 'https://saweatherstation.blob.core.windows.net/icons/cloudy.svg',
    '8': 'https://saweatherstation.blob.core.windows.net/icons/cloudy.svg',
    '11': 'https://saweatherstation.blob.core.windows.net/icons/cloudy.svg',
    /* Rainy Conditions */
    '12': 'https://saweatherstation.blob.core.windows.net/icons/raincloud.svg',
    '13': 'https://saweatherstation.blob.core.windows.net/icons/raincloud.svg',
    '14': 'https://saweatherstation.blob.core.windows.net/icons/raincloud.svg',
    '18': 'https://saweatherstation.blob.core.windows.net/icons/raincloud.svg',
    '39': 'https://saweatherstation.blob.core.windows.net/icons/raincloud.svg',
    '40': 'https://saweatherstation.blob.core.windows.net/icons/raincloud.svg',
    /* Snowy Conditions */
    '19': 'https://saweatherstation.blob.core.windows.net/icons/snow.svg',
    '20': 'https://saweatherstation.blob.core.windows.net/icons/snow.svg',
    '21': 'https://saweatherstation.blob.core.windows.net/icons/snow.svg',
    '22': 'https://saweatherstation.blob.core.windows.net/icons/snow.svg',
    '23': 'https://saweatherstation.blob.core.windows.net/icons/snow.svg',
    '25': 'https://saweatherstation.blob.core.windows.net/icons/snow.svg',
    '26': 'https://saweatherstation.blob.core.windows.net/icons/snow.svg',
    '29': 'https://saweatherstation.blob.core.windows.net/icons/snow.svg',
    '24': 'https://saweatherstation.blob.core.windows.net/icons/snow.svg',
    '31': 'https://saweatherstation.blob.core.windows.net/icons/snow.svg',
    '43': 'https://saweatherstation.blob.core.windows.net/icons/snow.svg',
    '44': 'https://saweatherstation.blob.core.windows.net/icons/snow.svg',
    /* Thunderstorm Conditions */
    '15': 'https://saweatherstation.blob.core.windows.net/icons/thunderstorm.svg',
    '16': 'https://saweatherstation.blob.core.windows.net/icons/thunderstorm.svg',
    '17': 'https://saweatherstation.blob.core.windows.net/icons/thunderstorm.svg',
    '41': 'https://saweatherstation.blob.core.windows.net/icons/thunderstorm.svg',
    '42': 'https://saweatherstation.blob.core.windows.net/icons/thunderstorm.svg',
    /* Windy Conditions */
    '32': 'https://saweatherstation.blob.core.windows.net/icons/wind.svg',
}