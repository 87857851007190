<template>
    <twitter>
        <a class="twitter-timeline" data-lang="en" data-chrome="transparent" data-theme="light" data-link-color="#3b3b3b"
            href="https://twitter.com/omnitech?ref_src=twsrc%5Etfw">Tweets by omnitech</a>
    </twitter>
</template>

<script>
export default {
    name: "twitterwidget",
}
</script>