<template>
    <div class="breakroom-dashboard-container">
            <ClockWidget class="clock-widget hidden-md-and-down rounded-corners" />
            <weather-widget id="weather-background" class="weather-widget rounded-corners"></weather-widget>
            <TwitterWidget class="twitter-widget hidden-md-and-down rounded-corners" />
            <Slideshow class="slideshow rounded-corners" />
            <DashboardLunches class="dashboard-lunches rounded-corners" />
    </div>
</template>

<script>
import ClockWidget from "./components/ClockWidget";
import TwitterWidget from "./components/TwitterWidget";
import WeatherWidget from "./components/WeatherWidget";
import DashboardLunches from "./components/DashboardLunches";
import Slideshow from "./components/Slideshow";

export default {
    name: 'breakroomdashboard',
    components: {
        TwitterWidget,
        WeatherWidget,
        ClockWidget,
        DashboardLunches,
        Slideshow,
    },
    beforeCreate() {
        let root = document.getElementsByTagName('html')[0];
        root.classList.add('kiosk-mode');
    },
    beforeDestroy() {
        let root = document.getElementsByTagName('html')[0];
        root.classList.remove('kiosk-mode');
    },
}

</script>

<style scoped>
.dashboard-lunches {
    grid-area: lunch;
    width: 100%;
    background: linear-gradient(245deg, rgba(67, 37, 128, 255) 0%, rgba(117, 87, 178, 255)100%);
}
.slideshow {
    grid-area: image;
    height: 100%;
    max-height: 100%;
}
.twitter-widget {
    grid-area: twitter;
}
.weather-widget {
    grid-area:weather;
    background-size: 100% 150%;
    background-position: center;
    min-height: 160px;
}
.clock-widget {
    grid-area: clock;
}
.breakroom-dashboard-container {
    display: grid;
    width: calc(100vw - 56px);
    height: 100vh;
    grid-template-areas: "clock weather lunch" "twitter image lunch";
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 1fr 4fr;
    gap: 10px;
    padding: 10px;
    background-color: rgb(230, 230, 230);
}

.rounded-corners {
    border-radius: 1.2vw/1.2vw;
    overflow: hidden;
}

/*Mirrors small - xs breakpoints in vuetify*/
@media screen and (max-width:1262px) {
    .breakroom-dashboard-container {
        display: flex;
        flex-direction: column;
        width: 100vw;
    }

    .weather-widget {
        min-height: 170px;
    }

    .twitter-widget {
        display: none;
    }
    .clock-widget {
        display: none;
    }
    .dashboard-lunches {
        order: 1;
        overflow: visible;
    }
    .slideshow {
        order: 2;
    }
}
</style>