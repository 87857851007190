<template>
    <section class="skeleton-weather-background">
        <div class="skeleton-weather">
            <div class="mobile-skeleton-current-info">
                <div class="skeleton-condition-icon skeleton"></div>
                <div class="skeleton-current-temp skeleton"></div>
                <p class="skeleton-text skeleton"></p>
            </div>
            <div class="mobile-skeleton-panel">
                <div class="skeleton-info-square">
                    <div class="skeleton-current-weather-icon skeleton"></div>
                    <p class="skeleton-text skeleton"></p>
                    <p class="skeleton-text skeleton"></p>
                </div>
                <div class="skeleton-info-square">
                    <div class="skeleton-current-weather-icon skeleton"></div>
                    <p class="skeleton-text skeleton"></p>
                    <p class="skeleton-text skeleton"></p>
                </div>
                <div class="skeleton-info-square">
                    <div class="skeleton-current-weather-icon skeleton"></div>
                    <p class="skeleton-text skeleton"></p>
                    <p class="skeleton-text skeleton"></p>
                </div>
                <div class="skeleton-info-square">
                    <div class="skeleton-current-weather-icon skeleton"></div>
                    <p class="skeleton-text skeleton"></p>
                    <p class="skeleton-text skeleton"></p>
                </div>
            </div>
            <div class="skeleton-static-info">
                <div class="skeleton-icon-and-temps">
                    <div class="skeleton-condition-and-temp">
                        <div class="skeleton-condition-icon skeleton"></div>
                        <div class="skeleton-current-temp skeleton"></div>
                    </div>
                    <p class="skeleton-text skeleton"></p>
                    <img class="skeleton-omnitech-building skeleton">
                </div>
            </div>
            <div class="skeleton-panel-wrapper">
                <ul class="skeleton-panel">
                    <li class="skeleton-current-weather-pane">
                        <div class="skeleton-current-weather-icon skeleton"></div>
                        <p class="skeleton-text skeleton"></p>
                        <p class="skeleton-text skeleton"></p>
                    </li>
                    <li class="skeleton-current-weather-pane">
                        <div class="skeleton-current-weather-icon skeleton"></div>
                        <p class="skeleton-text skeleton"></p>
                        <p class="skeleton-text skeleton"></p>
                    </li>
                    <li class="skeleton-current-weather-pane">
                        <div class="skeleton-current-weather-icon skeleton"></div>
                        <p class="skeleton-text skeleton"></p>
                        <p class="skeleton-text skeleton"></p>
                    </li>
                    <li class="skeleton-current-weather-pane">
                        <div class="skeleton-current-weather-icon skeleton"></div>
                        <p class="skeleton-text skeleton"></p>
                        <p class="skeleton-text skeleton"></p>
                    </li>
                </ul>
            </div>
            <div class="skeleton-right-art">
                <p class="skeleton-text skeleton"></p>
                <div class="skeleton-right-art-image skeleton"></div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "skeleton-loader",
    }
</script>

<style scoped>
    @keyframes skeleton-loading {
        0% {
            background-color: hsl(200, 20%, 80%);
        }

        100% {
            background-color: hsl(200, 20%, 95%);
        }
    }

    @media screen and (min-width: 1500px) {
        .skeleton-weather>.skeleton-right-art {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding-top: var(--pad-top);
            height: 100%;
        }
    }

    @media screen and (max-width: 1262px) and (min-width: 800px) {
        .skeleton-weather>.skeleton-right-art {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding-top: var(--pad-top);
            height: 100%;
        }
    }

    @media screen and (max-width: 700px) {
        .skeleton-weather>.skeleton-static-info {
            display: none;
        }

        .skeleton-weather>.skeleton-panel-wrapper {
            display: none;
        }

        .skeleton-weather>.mobile-skeleton-current-info {
            transform: scale(0.9);
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
        }

        .skeleton-weather>.mobile-skeleton-panel {
            transform: scale(0.75);
            display: grid;
            grid-template-columns: 50% 50%;
            grid-template-rows: 50% 50%;
            column-gap: 1em;
            row-gap: 1em;
        }
        
        .skeleton-info-square {
            display:flex;
            flex-direction: column;
            align-items:center;
        }
    }

    .mobile-skeleton-current-info {
        display: none;
    }

    .mobile-skeleton-panel {
        display: none;
    }

    p {
        margin: 0;
    }

    .skeleton {
        animation: skeleton-loading 1s linear infinite alternate;
    }

    .skeleton-condition-and-temp {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5em;
    }

    .skeleton-condition-icon {
        width: 50px;
        height: 50px;
        background-color: lightgrey;
        border-radius: 15%;
    }

    .skeleton-current-temp {
        width: 50px;
        height: 50px;
        background-color: lightgrey;
        border-radius: 15%;
    }

    .skeleton-current-weather-icon {
        width: 50px;
        height: 50px;
        background-color: lightgrey;
        border-radius: 15%;
    }

    .skeleton-current-weather-pane {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 0.5em;
    }

    .skeleton-icon-and-temps {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;
        height: 120%;
    }

    .skeleton-omnitech-building {
        width: 100px;
        height: 65px;
        background-color: lightgrey;
        border-radius: 15%;
    }

    .skeleton-panel {
        display: flex;
        justify-content: space-between;
    }

    .skeleton-panel-wrapper {
        width: min(60%, 50rem);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .skeleton-right-art {
        display: none;
    }

    .skeleton-right-art-image {
        width: 110px;
        height: 110px;
        background-color: lightgrey;
        border-radius: 15%;
    }

    .skeleton-static-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        padding-top: var(--pad-top);
        height: 100%;
    }

    .skeleton-text {
        width: 100px;
        height: 20px;
        background-color: lightgrey;
        border-radius: 15%;
    }

    .skeleton-weather {
        display: flex;
        flex-direction: row;
        padding-inline: var(--padding);
        --padding: 1.5em;
        --pad-top: 0.5em;
        justify-content: space-around;
        height: 100%;
    }
</style>