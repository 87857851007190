<template>
  <div class="lunches">
    <h1 class="text-center white--text">Lunch & Learn</h1>
    <hr>
    <h2 v-if="lunches.length === 0" class="full-width text-center white--text pt-3 px-3 pb-1">No lunches scheduled</h2>
  

    <v-card class="lunch" v-for="lunch in lunches" :key="lunch.id">
      <h2 class="text-center">{{ lunch.dayOfWeek }} - {{lunch.date | monthAndDate}}</h2>
      <strong class="text-center">{{ lunch.teamName }}</strong>
      <hr />
      <div v-if="lunch.foodName == ''" class="text-center">{{ lunch.restaurantName }}</div>
      <div v-else class="text-center">{{ lunch.restaurantName }} - {{ lunch.foodName }}</div>
    </v-card>
  </div>
</template>

<script>
import lunchDataAccess from '../../../dataAccess/lunchDataAccess';
export default {
  name: "DashboardLunches",
  data(){
    return {
      lunches: [],
      lunchDAO: new lunchDataAccess()
    }
  },
  methods: {
    async loadLunches(){
      let allLunches = [];
      if(this.isUsingAccessToken){
        allLunches = await this.lunchDAO.getLunchesWithAccessToken(this.$route.query.accessToken);
      }
      else{
        allLunches = await this.lunchDAO.getLunches();
      }

      allLunches.forEach(lunch => {
        lunch.date = new Date(lunch.eatDate);
        lunch.dayOfWeek = lunch.date.toLocaleDateString('en-EN', { weekday: 'long' });
      });

      var today = new Date();
      today.setHours(0, 0, 0, 0);

      this.lunches = allLunches.filter(lunch => {
        return lunch.date.getTime() >= today.getTime();
      }).slice(0, 5);
    }
  },
  mounted: async function(){
    await this.loadLunches();

    //Refresh lunches once per hour
    setInterval(this.loadLunches, 3600000);
  },
  computed: {
    isUsingAccessToken(){
      return this.$route.query.accessToken != "" && this.$route.query.accessToken != undefined;
    }
  }
}
</script>

<style scoped>

h1 {
  font-weight: 500px;
  font-size: 28px;
  padding-bottom: 10px;
}

h2 {
  font-weight: normal;
}

hr {
  width: 85%;
  margin-inline: auto;
}

.lunches{
  display: flex;
  flex-direction: column;
  text-align: left;
  padding:8px;
  padding-top: 20px;
}

.lunch{
  display: flex;
  font-size: 12pt;
  margin: 5px;
  flex-direction: column;
  justify-content: center;
}

.lunch:last-child{
  border: none;
}

.lunch *{
  margin: 5px 10px;
}

.full-width {
  width: 100%;
}

.text-center {
  text-align:center;
}
</style>