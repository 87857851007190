<template>
  <div class="slideshow-container">
    <img class="slide-image" :src="currentImageBytes" />
  </div>
</template>

<script>
import imageDataAccess from '../../../dataAccess/imageDataAccess';
import dashboardConfigDataAccess from '../../../dataAccess/dashboardConfigDataAccess';
import { setInterval } from 'timers';
export default {
  name: "Slideshow",
  data(){
    return {
      images: [],
      config: {
        imageDisplayInterval: 10
      },
      imageDAO: new imageDataAccess(),
      configDAO: new dashboardConfigDataAccess(),
      currentImageIndex: 0,
      currentImageBytes: null
    }
  },
  methods: {
    async loadImages(){
      if(this.isUsingAccessToken){
        this.images = await this.imageDAO.getImagesWithAccessToken(this.$route.query.accessToken);
      }
      else{
        this.images = await this.imageDAO.getImages();
      }
    },
    async loadConfig(){
      if(this.isUsingAccessToken){
        this.config = await this.configDAO.getConfigWithAccessToken(this.$route.query.accessToken);
      }
      else{
        this.config = await this.configDAO.getConfig();
      }
    },
    nextImage(){
      this.currentImageIndex++;
      if(this.currentImageIndex >= this.images.length) this.currentImageIndex = 0;

      this.currentImageBytes = this.images[this.currentImageIndex].imageBytes;
      setTimeout(this.nextImage, this.config.imageDisplayInterval * 1000);
    }
  },
  mounted: async function(){
    await this.loadImages();
    await this.loadConfig();
    this.nextImage();

    setInterval(async () => {
      await this.loadImages();
      await this.loadConfig();
    }, 3600000)
  },
  computed: {
    isUsingAccessToken(){
      return this.$route.query.accessToken != "" && this.$route.query.accessToken != undefined;
    }
  }
}
</script>

<style scoped>
.slideshow-container{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-bottom:0
}

.slide-image{
  object-fit: cover;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  height: 100%;
}

</style>