<template>
    <section class="wrapper">
        <carousel @page-change="changePanel" class="carousel" :perPage="1" :centerMode="true" :loop="true"
            :paginationEnabled="false" :adjustableHeight="false">
            <slide>
                <mobile-current-weather class="panel" :currentWeather="weatherData.currentWeather"
                    :location="weatherData.location" />
            </slide>
            <slide>
                <hourly-forecast class="panel forecast-panel" :hourlyWeather="weatherData.hourlyWeather" />
            </slide>
            <slide>
                <weekly-forecast class="panel forecast-panel" :dailyWeather="weatherData.dailyWeather" />
            </slide>
        </carousel>
        <div class="navigation">
            <div id="first-nav-dot" class="navigation-dot" filled></div>
            <div class="navigation-dot"></div>
            <div class="navigation-dot"></div>
        </div>
    </section>
</template>

<script>
    import MobileCurrentWeather from "./MobileCurrentWeather.vue";
    import HourlyForecast from "./HourlyForecast.vue";
    import WeeklyForecast from "./WeeklyForecast.vue";
    import { Carousel, Slide } from 'vue-carousel';

    export default {
        name: "mobile-weather-widget",
        components: {
            MobileCurrentWeather,
            HourlyForecast,
            WeeklyForecast,
            Carousel,
            Slide
        },
        props: {
            weatherData: Object,
        },
        mounted() {
            document.getElementById("first-nav-dot").setAttribute("filled", "");
        },
        methods: {
            changePanel(panelNumber) {
                document.getElementsByClassName("navigation-dot")
                    .forEach((e, index) => {
                        if (index === panelNumber)
                            e.setAttribute("filled", "");
                        else
                            e.removeAttribute("filled");
                    });
            }
        }
    }
</script>

<style scoped>
    .forecast-panel {
        margin-top: 0.5em;
    }

    .navigation {
        display: flex;
        flex-direction: row;
        position: absolute;
        top: 0.5em;
        justify-content: center;
        width: 100%;
    }

    .navigation-dot {
        width: 10px;
        height: 10px;
        margin-inline: 5px;
        border: 1px solid rgb(83, 83, 83);
        border-radius: 50%;
        transition: background-color 0.5s;
    }

    .navigation-dot[filled] {
        background-color: rgb(83, 83, 83);
    }

    .carousel {
        overflow-x: hidden;
    }

    .panel {
        display: flex;
        transform: scale(0.90);
    }

    .wrapper {
        position: relative;
        --padTop: 1em;
        padding-top: var(--padTop);
        font-family: "Cerebri";
        color: rgb(65, 65, 65);
    }
</style>