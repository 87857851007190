<template>
    <section class="wrapper">
        <div class="weather" v-if="isLoaded">
            <div class="static-weather-block fade-in">
                <div class="icon-and-temps">
                    <div class="current-condition-and-temp">
                        <img class="current-condition-icon"
                            :src="generateConditionIcon(weatherData.currentWeather.conditionCode)"
                            :alt="weatherData.currentWeather.condition" />

                        <p class="current-temp">
                            <b>{{ currentTemp }}</b><span class="current-temp-degree-f">&deg;F</span>
                        </p>
                    </div>
                    <p>
                        FEELS LIKE {{ feelsLike }}<span class="feels-like-degree-f">&deg;F</span>
                    </p>
                </div>
                <img class="omnitech-building"
                    src="https://saweatherstation.blob.core.windows.net/images/omnitech_building.png"
                    aria-hidden="true" />
            </div>

            <!-- Panels -->
            <current-weather id="panel1" class="panel" role="tab-panel" aria-expanded="true"
                :currentWeather="weatherData.currentWeather" :location="weatherData.location">
            </current-weather>
            <hourly-forecast id="panel2" class="panel" role="tab-panel" aria-expanded="false" hidden
                :hourlyWeather="weatherData.hourlyWeather"></hourly-forecast>
            <weekly-forecast id="panel3" class="panel" role="tab-panel" aria-expanded="false" hidden
                :dailyWeather="weatherData.dailyWeather"></weekly-forecast>

            <!-- Right art -->
            <div class="right-art">
                <p class="location">{{ weatherData.location }}</p>
                <img class="right-art-image" :src="getTreeImage()" aria-hidden="true" />
            </div>
        </div>
        <div class="navigation" v-if="isLoaded">
            <ul class="nav-dots" role="tab-list" aria-label="Panel Navigation">
                <li filled id="dot1" class="nav-dot" role="tab" aria-controls="panel1" aria-selected="true"
                    @click="navigateToPanel(1)" @keyup.enter="navigateToPanel(1)" tabindex="0"></li>
                <li id="dot2" class="nav-dot" role="tab" aria-controls="panel2" aria-selected="false"
                    @click="navigateToPanel(2)" @keyup.enter="navigateToPanel(2)" tabindex="0"></li>
                <li id="dot3" class="nav-dot" role="tab" aria-controls="panel3" aria-selected="false"
                    @click="navigateToPanel(3)" @keyup.enter="navigateToPanel(3)" tabindex="0"></li>
            </ul>
            <img role="button" v-if="cyclePaused" @click="restartCycle()" @keyup.enter="restartCycle()"
                class="restart-icon" src="https://saweatherstation.blob.core.windows.net/icons/restart.svg"
                tabindex="0" />
        </div>
    </section>
</template>

<script>
const MorningTree = "https://saweatherstation.blob.core.windows.net/images/morning_tree.svg";
const DayTree = "https://saweatherstation.blob.core.windows.net/images/day_tree.svg";
const EveningTree = "https://saweatherstation.blob.core.windows.net/images/evening_tree.svg";
import { Icons } from "../../../../../public/iconCodes.js";
import CurrentWeather from "./CurrentWeather.vue";
import HourlyForecast from "./HourlyForecast.vue";
import WeeklyForecast from "./WeeklyForecast.vue";

export default {
    name: "desktop-weather-widget",
    components: {
        CurrentWeather,
        HourlyForecast,
        WeeklyForecast,
    },
    props: {
        weatherData: Object
    },
    data() {
        return {
            isLoaded: false,
            cycleInterval: null,
            cycleIntervalDelayInMS: 15000,
            cyclePaused: false,
            transitioning: false,
        };
    },
    computed: {
        currentTemp() {
            return parseInt(this.weatherData.currentWeather.temperature);
        },

        feelsLike() {
            return parseInt(this.weatherData.currentWeather.temperatureFeelsLike);
        },
    },
    mounted() {
        this.isLoaded = true;
        this.start();
    },
    destroyed() {
        window.clearInterval(this.cycleInterval);
    },
    methods: {
        getTreeImage() {
                let hourOfTheDay = new Date().getHours();
                if (hourOfTheDay < 11 && hourOfTheDay >= 6) {
                    return MorningTree;
                }
                else if (hourOfTheDay >= 11 && hourOfTheDay < 15) {
                    return DayTree;
                }
                else {
                    return EveningTree;
                }
            },
        cyclePanels() {
            let currentPanel = 1;
            this.cycleInterval = window.setInterval(() => {
                if (currentPanel === 3)
                    currentPanel = 1;
                else
                    currentPanel++;
                this.setPanel(currentPanel);
            }, this.cycleIntervalDelayInMS);
        },
        fadeInPanel(panel, navDot) {
            setTimeout(() => {
                panel.style.display = "flex";
                panel.ariaExpanded = "true";
                panel.removeAttribute("hidden");
                this.transitioning = false;
            }, 1450);
            panel.classList.remove("fade-out");
            panel.classList.add("fade-in");

            setTimeout(() => {
                navDot.setAttribute("filled", "");
                navDot.ariaSelected = "true";
            }, 1300);
        },
        fadeOutPanel(panel, navDot) {
            panel.classList.add("fade-out");
            panel.classList.remove("fade-in");
            panel.ariaExpanded = "false";
            panel.setAttribute("hidden", "");

            navDot.removeAttribute("filled");
            navDot.ariaSelected = "false";

            setTimeout(() => {
                panel.style.display = "none";
            }, 1450);
        },
        generateConditionIcon(conditionCode) {
            return Icons[conditionCode];
        },
        navigateToPanel(panel) {
            if (!this.transitioning) {
                window.clearInterval(this.cycleInterval);
                this.setPanel(panel);
                this.cyclePaused = true;
            }
        },
        restartCycle() {
            if (!this.transitioning) {
                this.setPanel(1);
                this.cyclePanels();
                this.cyclePaused = false;
            }
        },
        setPanel(currentPanel) {
            let numPanels = 3;
            this.transitioning = true;
            for (let i = 1; i <= numPanels; i++) {
                let panel = document.getElementById(`panel${i}`);
                let navDot = document.getElementById(`dot${i}`);
                if (i !== currentPanel) {
                    this.fadeOutPanel(panel, navDot);
                }
                else {
                    this.fadeInPanel(panel, navDot);
                }
            }
        },
        start() {
            setTimeout(() => {
                this.setPanel(1);
                this.cyclePanels();
                this.showSkeleton = false;
            });
        }
    },
};
</script>

<style scoped>
ul {
    padding: 0;
}

@font-face {
    font-family: Cerebri;
    src: url("/Fonts/Cerebri-Sans-Regular.ttf");
}

.current-condition-and-temp {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
}

.current-condition-icon {
    width: 50px;
}

.current-temp {
    line-height: 1;
    font-size: 70px;
}

.current-temp-degree-f {
    position: relative;
    bottom: 28px;
    font-size: 30px;
    font-weight: normal;
}

.nav-dot {
    height: 10px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 1px solid rgb(83, 83, 83);
    transition: background-color 2s;
    cursor: pointer;
}

.nav-dot[filled] {
    background-color: rgb(83, 83, 83);
}

.nav-dots {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 6em;
}

.navigation {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    right: 15px;
    float: right;
    top: 0;
}

.fade-in {
    animation: fadeIn 1.5s;
}

.fade-out {
    animation: fadeOut 1.5s;
}

.feels-like-degree-f {
    vertical-align: text-top;
    font-size: 13px;
}

.icon-and-temps {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

li {
    list-style-type: none;
}

.location {
    font-size: 20px;
    width: 13ch;
}

.omnitech-building {
    width: 125px;
}

p {
    margin: 0px;
    margin-block-end: 0;
    margin-block-start: 0;
}

.panel {
    width: min(60%, 50rem);
    justify-content: space-evenly;
}

.restart-icon {
    height: 20px;
    position: absolute;
    bottom: 0.75em;
}

.skeleton-load {
    min-height: 100%;
}

.right-art {
    display: none;
}

@media screen and (min-width: 1500px) {
    .right-art {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: var(--pad-top);
        height: 100%;
    }
}

@media screen and (max-width: 1262px) and (min-width: 800px) {
    .right-art {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: var(--pad-top);
        height: 100%;
    }
}

.right-art-image {
    width: 110px;
}

.static-weather-block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: var(--pad-top);
    height: 100%;
}

ul {
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

.weather {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-inline: var(--padding);
    height: 100%;
}

.wrapper {
    background-size: cover;
    --padding: 1.5em;
    --pad-top: 0.5em;
    font-family: "Cerebri";
    color: rgb(65, 65, 65);
    position: relative;
}

/* animations */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>